@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,900&display=swap');

* {
  
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

.form{
  display: flex;
  flex-direction: column;
  max-width: 340px;
  gap: 10px;
  align-items: center;
  background-color: #242424;
  padding: 10px 54px 54px 54px;
  
  border-radius: 24px;
  box-shadow: rgb(101, 29, 226) 14px 10px 1px 0;
  
}
body{
  background-color: white;
}
h3{
  color: #8610bd;
  font-size: x-large;
  
}
input{
  outline: none;
  padding: 8px;
border: 2px solid #8610bd;
  border-top: none;
  border-left: none;
  border-right: none;
  transition: all 300ms ease;
  

 
}
input:focus{
  border: 2px solid olivedrab;
  border-top: none;
  border-left: none;
  border-right: none;

}
.form > button {
  padding:10px 35px;
  background-color: #242424;
  color: #38caef;
  font-weight: bold;
  cursor: pointer;
  border: #38caef 1px solid;
  font-size: medium;
  transition: all 300ms ease;
  margin-top: 24px;

}
.form > button:active{
  transform: scale(1.0);
}
.form > button:hover{
  transform: scale(1.09);
}
.form > h3{
  color: #38caef;
}

.formContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}
.na{
  background-color: #242424;
  border-radius: 24px;
  padding: 14px;
  box-shadow: black 0 24px 24px 0;
  margin-bottom: 44px;
  margin-right: 24px;
  color: white;
  box-shadow: #242424 14px 10px 24px 0;

margin-bottom: 34px;
width: 400px;
  
 
  
  
  
  
  font-size: x-small;
  
 
  
}
@media (max-width:650px) {
  .na{
   
    font-size: xx-small;
    width: 300px;
    
    
  }
  .work{
  
  }
  
}

.button {
  padding: 5px 30px;
  background-color: #242424;
  color: #8610bd;
  font-weight: bold;
  cursor: pointer;
  border: #8610bd 1px solid;
  font-size: larger;
  transition: all 300ms ease;
}
.work{
display: flex;
align-items: center;
justify-content: center;

flex-wrap: wrap;



}

.workContain{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  

}
#w{
  font-size: 34px;
  margin-right:100px ;
}
.nmma{

}
.nav{
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  cursor: pointer;
  align-items: center;


}
ul{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-right: 24px;


}
.btnNav{
  background-color: transparent;
  border: none;
  color: #8610bd;
  font-weight: 900;
  cursor: pointer;
  font-size: 16px;
}
li{
  
  color: #8610bd;
  cursor: pointer;
  list-style-type: none;
  font-weight: 900;
  
}
.bb{
  text-decoration: none;
}
.home{
  display: flex;
  
  
  flex-direction: column;
}
.homeC{
 position: absolute;
 
 top: 20%;
}
.p{
  color: #914eb1;
}

.ti{
  color: black;
}
.btnContain{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.btn{
  background-color: #8610bd;
  border: none;
  padding: 14px 34px;
  color: white;
  cursor: pointer;
  font-size: medium;
  border-radius: 9px;
}

.ss{
  background-color: #8610bd;
  border: none;
  padding: 12px 30px;
  color: white;
  cursor: pointer;
  font-size: medium;
  border-radius: 9px;
}
.err{
  background-color: #242424;
  color: #8610bd;
  padding: 14px 34px;
  border-radius: 10px;
  font-weight: bold;
 
}
.x{
  display: none;
}
.navLogo{
  height: 120px;


}

@media (max-width:528px) {
  .homeimage{
    margin-top: 24px;
    margin-bottom: 34px;
    width: 400px;
    
    object-fit: contain;

  }
  
  .nav{
margin-left:5px ;


  }
  .r{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nd{
    display: block;
   
  }
  .ndd{
    display: none;
  }
 .navm{
  display: none;
 }
 .x{
  display: block;
  position: absolute;
  right: 5%;
 
  
 }
 .navt{
  font-size: 24px;
  border-bottom: 1px solid white ;
  color: white !important;
  
 }
 .nd > ul {
  position: absolute;
 left: 0%;
  top: 5%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: #242424;
  height: 100%;
  width: 100%;
  margin-top: 80px;
  

 }

  .ss{
    padding: 10px;
    font-size: small;
  }
  li{
    font-size: small;
  }


  .btnContain{
    flex-direction: column;
  }
  h2{
    font-size: medium;
  }
  #w{
    font-size: medium;
  }
  .hometext > h1{
    font-size: 25px !important;
  }
  .hometext > h4{
    font-size: 15px !important;
  }
  .WorkContainform{
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 24px;
    
      
  }
  .c{
    flex-direction: column;
   

  }
 
 
 

  
  
}


.load{
  color: #8610bd;
  animation: spin infinite 1000ms linear;
  font-weight: 900;
}
@keyframes spin {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}
.jobD{
  display: flex;
  flex-direction: column;
 
  
  max-width: 1000px;
margin-bottom: 30px;
}

.nmma {
  display: none;
}



.post{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 34px;
  
}
.postform{
  border: 4px solid purple;
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 30px;
  box-shadow: #242424 34px 19px 14px 4px;
  border-radius: 24px;
  margin-left: 24px;
  margin-right: 24px;
 width: 100%;
 max-width: 700px;
 
}
.des{
  height: 300px;
  width: 100%;
  resize: none;
   border: #8610bd 2px solid;
   outline: none;
   color: black;
   font-weight: 900;
   font-size: large;
   
}
.des::placeholder{
  color: #914eb1;
   font-weight: 600;
}
.des:focus{
  border: olivedrab 2px solid;
}
.postform > input::placeholder{
  color: #914eb1;
  font-weight: 600;
}
.postform > input {
  color: black;
  font-weight: 900;
  font-size: large;
}
.postform > h3{
  color: #733491;
}





.cssbuttons-io-button {
  margin-top: 24px;
  background: #A370F0;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  
 }
 
 .cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
 }
 
 .cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
 }
 
 .cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
 }
 
 .cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
 }
 
 .cssbuttons-io-button:active .icon {
  transform: scale(0.95);
 }
 

 


 .form__group {
  position: relative;
  padding: 20px 0 0;
  margin-bottom: 10px;
  width: 100%;
  max-width: 180px;
}
.formContainer{
  margin-top: 60px;
}
.formContainer > button {
  margin-top: 20px;
 
  
}
.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 17px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 17px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #9b9b9b;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #116399, #38caef);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #38caef;
  font-weight: 700;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}


.loader {
  --path: #2f3545;
  --dot: #5628ee;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect, .loader svg polygon, .loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}

section{
  height: 100vh;
  width: 100vw;
}
.row{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.message{
  background-color: #242424;
  height: 80vh;
  position: relative;
  overflow-y: auto;
}

.sent{
  padding:5px 12px;
  margin-left: 10px;
  border-radius: 24px 24px 24px 0;
  background-color: #38caef;
  width: fit-content;
}
.rec{
  padding:5px 12px;
  margin-left: 10px;
  border-radius: 24px 24px 24px 0;
  

  background-color: #bf5700;
  width: fit-content;
}
.im{
  width: 90%;
  height: 50px;
  border: #38caef 2px solid !important;
  outline: none;

}

.imb{
  width: 10%;
  height: 50px;
  background-color: #38caef;
}
.mf{

}
.chatbox{
  display: flex;
  justify-content: space-between;
  border: 2px solid #5628ee;
  padding: 24px;
  max-width: 1200px;
  align-items: center;
  margin: 0 auto;
  border-radius: 14px;
  font-size: 20px;

  
}
.chatboxcontain{
  text-align: center;
 
  
}

.cb{
  padding: 0.5em 1.8em;
  border: 2px solid #00ffe5;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: #1e7e74;
  cursor: pointer;
 }

 .img{
  width: 350px;
  height: 250px;
  margin-left: 24px;
  border-radius: 14px;


 

 }
 .rown > h1 {
  margin-bottom: 194px;
 }
 .imgContain{
  display: flex;
 }

 .hidden{
  opacity: 0;
  filter: blur(5px);
  transition: all 2s ease;
  transform: translateX(-100%);
 }
 .show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);



 }
 
 .hn{
  background-color: #9b9b9b;
  height: max-content ;
  
 }
 #wh{
  color: black;
 }
 

 .logo:nth-child(2){
  transition-delay:400ms ;

 }
 .rown{
  display: flex;
  align-items: center;
  flex-direction: column;

  color: white;
 }
 .footer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 }
 .appliedC{
  background-color: #242424;
  color: white;

 }
 .appliedC > a {
  text-decoration: none;
  color: white;
 }
 .nnn{
  display: flex;
align-items: center;
  justify-content: space-between;
 
 }
 .hireBtn{
cursor: pointer;
background-color: #7b52b9;
color: white;
border: none;
padding: 10px 24px;
 }
 .applyForm{
  display: flex;
  flex-direction: column;
  background-color: #2f3545;
  color: white;
  gap: 10px;
  padding:24px ;
  max-width: 700px;
  margin: 60px auto;
  box-shadow: 34px 24px 24px 10px black;
  border-radius: 24px;


 }
.applyForm > input{
 border: black 2px;
 border-radius: 5px;
}
.applyForm > button{
  cursor: pointer;
background-color: #7b52b9;
color: white;
border: none;
padding: 10px 24px;
max-width: 200px;
}
.WorkContainform{
  display: flex;
 
  align-items: center;


  

}
.WorkContainform > input{
  background-color: #000000;
  color: white;
  height: 50px;
  border-radius: 20px;
  font-size: larger;

  
}

.WorkContainform > input::placeholder{
  color: white;

  
}

:root {  
  --purple: rgb(123, 31, 162);
  --violet: rgb(103, 58, 183);
  --pink: rgb(244, 143, 177);
}



@keyframes background-pan {
  from {
    background-position: 0% center;
  }
  
  to {
    background-position: -200% center;
  }
}
.hometext{
 margin-top: 90px;
}
.hometext > h1 {
  font-size: 44px;

}
.hometext > h2 {
  line-height: 20px;

}

.gradient{
  
    animation: background-pan 3s linear infinite;
    background: linear-gradient(
      to right,
      var(--purple),
      var(--violet),
      var(--pink),
      var(--purple)
    );
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
  

}
.c{
  display: flex;
  align-items: center;
  gap: 20px;
}